<template>
  <div v-loading="loading" class="statTypeSetting">
    <div class="typeSetting">
      <div class="title">基础统计设置</div>
      <div class="box mb18">
        <div v-if="$route.query.examType == 1" class="row mb18">
          <div class="label">统计区域设置:</div>
          <el-button @click="areaSetting">设置</el-button>
          <span
            v-for="(item, index) in areaNames"
            :key="index"
            style="margin-left: 8px"
            >{{ item }}</span
          >
        </div>
        <div v-if="$route.query.examType == 1" class="row mb18">
          <div class="label">不统计的学校:</div>

          <el-button @click="schoolSetting">设置</el-button>
          <span
            v-for="(item, index) in schoolIncludes"
            :key="index"
            style="margin-left: 8px"
            >{{ item.name }}</span
          >
        </div>
        <div v-if="$route.query.examType == 1" class="row mb18">
          <div class="label">是否生成班级相关统计：</div>
          <el-radio-group v-model="form.statClass" @change="statClassChange">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </div>
        <div class="row mb18">
          <div class="label">统计基数设置：</div>
          <div class="round-checkBox">
            <div class="radioGroup">
              <div
                v-for="(item, index) in statTypeList"
                :key="index"
                :class="[
                  form.baseType === index + 1
                    ? 'radio-item-active'
                    : 'radio-item',
                  scoreType === 1 && index === 1 ? 'radio-item-disabled' : '',
                ]"
                @click="radioChange(index)"
              >
                <div class="point"></div>
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="form.baseType === 1" class="row">
          <div class="label">参统条件设置：</div>
          <div class="round-checkBox">
            <el-checkbox
              v-model="form.excludeZero"
              :true-label="1"
              :false-label="0"
              @change="excludeZeroChange"
              >零分不参统
            </el-checkbox>
          </div>
        </div>
      </div>
      <div v-if="$route.query.examType == 1" class="tag">
        <div class="tag-title">
          <a-icon type="question-circle" theme="filled" />统计基数设置
        </div>
        <div class="text">
          作用于统计结果中的《3.各项指标对比》，包括赋分人数、最高分、平均分、中位数、标准差、优秀事、及格率等;同时也作用于《4.前N名对比》
        </div>
        <div class="tag-title">零分不参与统计</div>
        <div class="text">
          如果总分是“0”分，那么总分不参与统计;如果某个科目为“0”分，那么该科不参与统计。
        </div>
      </div>
      <div v-if="form.baseType === 1 && $route.query.examType != 1" class="tag">
        <div class="tag-title"><i class="el-icon-question"></i>零分不参统</div>
        <div class="text">
          若总分是“0”分，那么总分不参与统计；如果某参考科目（包含小语种）是“0”分，那么该科目不参与统计；如果某选考科目赋分是“0”分，那么该科目不参与统计。
        </div>
        <div class="tag-title" style="margin-top: 8px">缺考不参统</div>
        <div class="text">
          若考生未选考科目或选考但未予以赋分（如：零分不参与赋分），则该考生此学科不参与统计。
        </div>
      </div>
      <div v-if="form.baseType === 1" class="title">高级统计设置</div>
      <div v-if="form.baseType === 1">
        <div class="filter">
          <div class="row">
            <div class="label">选择学生</div>
            <el-select
              v-model="search.studentType"
              placeholder="请选择"
              style="width: 188px"
              @change="searchChange"
            >
              <el-option
                v-for="item in studentTypeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div v-if="$route.query.examType == 1" class="row">
            <div class="label">选择学校</div>
            <el-select
              v-model="search.schoolId"
              placeholder="请选择"
              style="width: 220px"
              clearable
              @change="searchChange"
            >
              <el-option
                v-for="item in schoolList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else class="row">
            <div class="label">行政班级</div>
            <el-select
              v-model="search.classNum"
              placeholder="请选择"
              style="width: 120px"
              @change="searchChange"
            >
              <el-option
                v-for="item in classNumOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="table-search">
          <div class="title">学生名单</div>
          <div>
            <el-input
              v-model="search.input"
              style="width: 150px; margin-right: 8px"
              class="mb18"
              placeholder="考号或学生姓名"
              suffix-icon="el-icon-search"
              @input="searchChange"
            >
            </el-input>
            <el-button
              v-if="$route.query.examType == 1"
              type="primary"
              @click="uploadSchoolStudent"
            >
              <img
                src="@/assets/uExam/icon_export.png"
                style="margin-right: 4px"
                alt=""
              />
              <img
                class="on"
                src="@/assets/uExam/icon_export.png"
                style="margin-right: 4px"
                alt=""
              />
              导入不参与学校统计的考生</el-button
            >
            <el-button
              v-if="$route.query.examType == 1"
              type="danger"
              @click="clearSchoolStudent"
            >
              <img
                src="@/assets/uExam/clear-white.png"
                style="margin-right: 4px"
                alt=""
              />
              <img
                class="on"
                src="@/assets/uExam/clear-white.png"
                style="margin-right: 4px"
                alt=""
              />
              清空不参与学校统计考生</el-button
            >
            <el-button
              v-else
              type="primary"
              icon="el-icon-upload"
              @click="uploadResult"
              >导入不参统考生</el-button
            >
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          :header-cell-style="{ background: '#F2F5F8' }"
        >
          <el-table-column
            prop="name"
            label="姓 名"
            width="120"
            align="center"
          />
          <el-table-column
            prop="studentExamId"
            width="150"
            label="考 号"
            align="center"
          />
          <el-table-column
            v-if="$route.query.examType == 1"
            prop="schoolName"
            label="学 校"
            width="220"
            align="center"
          />
          <el-table-column
            v-else
            prop="classNum"
            label="班 级"
            width="220"
            align="center"
          />
          <el-table-column
            v-if="$route.query.examType == 1"
            prop="isIncludeSchool"
            label="不参与学校统计"
            width="220"
            align="center"
          >
            <template slot-scope="{ row }">
              <el-checkbox
                v-model="row.isIncludeSchool"
                :true-label="0"
                :false-label="1"
                @change="(e) => studentChange(e, row)"
              ></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column
            v-if="scoreType == 1"
            label="选考组合"
            prop="eleccomb"
            width="100"
            align="center"
          />
          <el-table-column
            :label="
              $route.query.examType == 1 ? '参与学校统计科目' : '参统科目'
            "
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="{ row }">
              <template v-for="(item, index) in row.subjects">
                <span
                  v-if="item.isInclude == 1"
                  :key="index"
                  :class="item.subjectId == 0 ? 'ft-red' : ''"
                  >{{ item.subjectName }}；</span
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column
            label="不参统科目"
            align="center"
            show-overflow-tooltip
          >
            <template slot="header">
              <span class="marginR20">不参统科目</span>
              <el-button
                icon="el-icon-setting"
                @click="
                  quickDialogVisible = true;
                  title = '快捷设置';
                "
                >快捷设置</el-button
              >
            </template>
            <template slot-scope="{ row }">
              <template v-for="(item, index) in row.subjects">
                <span
                  v-if="item.isInclude == 0"
                  :key="index"
                  :class="item.subjectId == 0 ? 'ft-red' : ''"
                  >{{ item.subjectName }}；</span
                >
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操 作" width="80" align="center">
            <template slot-scope="{ row }">
              <el-button type="text" @click="setting(row)">设置</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="search.current"
            :page-sizes="[10, 20, 50, 100, 200, 500, 1000]"
            :page-size="search.size"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div
        v-if="form.baseType === 2 && $route.query.examType != 1"
        class="fixedNumStat"
      >
        <div class="label">
          班级固定统计人数设置
          <el-button type="primary" @click="initSystemNum"
            >初始化为系统人数</el-button
          >
        </div>
        <div class="fixedNumStat-content">
          <div
            v-for="(item, index) in form.statClassSettings"
            :key="index"
            class="fixedNumStat-content-item"
          >
            <div class="fixedNumStat-content-item-label">
              {{ item.classNum }}统计人数：
            </div>
            <el-input
              v-model="item.fixedNumber"
              style="width: 60px; margin: 0 8px"
            >
            </el-input>
            ({{ item.total }})
          </div>
        </div>
      </div>

      <div
        v-if="form.baseType === 2 && $route.query.examType == 1"
        class="schoolStat"
      >
        <div class="title">
          学校固定统计人数设置
          <a-button
            type="primary"
            ghost
            style="margin-left: 8px"
            @click="settingMax"
            >设为最大人数</a-button
          >
        </div>

        <a-form-model ref="statSchoolSettingsForm" :model="form">
          <div class="schoolList">
            <a-form-model-item
              v-for="(item, index) in form.statSchoolSettings"
              :key="index"
              :prop="'statSchoolSettings.' + index + '.fixedNumber'"
              :rules="{
                validator: (rule, value, callback) =>
                  validFixedNumber(rule, value, callback, index),
                trigger: 'blur',
              }"
            >
              <div class="schoolList-item">
                <div class="schoolList-item-title">{{ item.name }}</div>
                <div class="settingNum">
                  <a-input
                    v-model="item.fixedNumber"
                    style="width: 100px"
                    placeholder="统计人数"
                  ></a-input>
                  / {{ item.total }}
                </div>
              </div>
            </a-form-model-item>
          </div>
        </a-form-model>
      </div>

      <div v-if="form.baseType === 2" class="save">
        <el-button
          v-if="$route.query.examType == 1"
          type="primary"
          :loading="saveLoading"
          @click="baseType2submit"
          >保存</el-button
        >
        <el-button v-else type="primary" :loading="saveLoading" @click="submit"
          >保存</el-button
        >
      </div>
    </div>
    <el-dialog
      title="设置不参与统计"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form label-position="right">
        <el-form-item label="姓 名:">{{ rowItem.name }}</el-form-item>
        <el-form-item label="班 级:">{{ rowItem.classNum }}</el-form-item>
        <el-form-item label="考 号:">{{ rowItem.studentExamId }}</el-form-item>
        <el-form-item label="不参与统计:">
          <el-checkbox-group v-model="rowItem.subjectIds">
            <el-checkbox
              v-for="(item, index) in subjectList"
              :key="index"
              :label="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="">
          <el-button type="text" @click="allCheck">全选</el-button>
          <el-button type="text" @click="reverse">反选</el-button>
          <el-button type="text" @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="rowLoading" @click="saveItem"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="title"
      :visible.sync="quickDialogVisible"
      width="45%"
      :before-close="handleCloseQuick"
    >
      <template v-if="title == '快捷设置'">
        <div v-loading="quickLoading" class="quick-setting">
          <div class="left">
            <div class="left-box">
              <el-checkbox
                v-model="quickForm.excludeZero"
                :true-label="1"
                :false-label="0"
                style="margin-bottom: 32px"
                @change="excludeZeroChanges"
                >零分不参统</el-checkbox
              >
              <el-checkbox
                v-model="quickForm.excludeNoSheet"
                style="margin-bottom: 32px"
                :true-label="1"
                :false-label="0"
                :disabled="checkboxDisable"
                >缺考或无卡不参统</el-checkbox
              >
              <el-checkbox
                v-model="quickForm.excludeSubjectZero"
                style="margin-bottom: 32px"
                :true-label="1"
                :false-label="0"
                >单科零分 则 总分不参统</el-checkbox
              >

              <el-checkbox
                v-model="quickForm.excludeSubjectNoSheet"
                :true-label="1"
                :false-label="0"
                >单科缺考/无卡 则 总分不参统</el-checkbox
              >
            </div>
            <div v-if="$route.query.examType == 1" class="upload-box">
              <ImportUpload
                :file-model="{
                  name: '导入不参统考生.xls',
                  url: `https://static.wtjy.com/stat/template/%E5%AF%BC%E5%85%A5%E4%B8%8D%E5%8F%82%E4%B8%8E%E7%BB%9F%E8%AE%A1%E5%AD%A6%E7%94%9F%E6%A8%A1%E6%9D%BF.xls`,
                }"
                :disabled="confirmLoading"
                style="margin-bottom: 18px"
                @change="uploadChange"
              />
              <a-button type="primary" @click="submitMuchImport">导入</a-button>
            </div>
          </div>
          <div class="tipinfo">
            <div class="tip">【提示】</div>
            <div>
              1、零分不参统：如果总分是“0”分，那么总分不参与统计；如果某个科目为“0”分，那么该科不参与统计。缺考科目默认为零分。
              如选考科目，则选考科目赋分为“0”分时该科目不参与统计。
            </div>
            <div>
              2、单科零分则总分不参统：某个考生，只要有一科为“0”分，则总分也不参与统计。
              如选考科目，则是选考科目赋分为“0”分，则总分不参与统计。
              <span class="ft-red"
                >（如果尚未导入日语、朝语文这样的小语种成绩，不能轻易设置该操作）</span
              >。
            </div>
            <div>
              3、缺考或无卡不参与统计：某个考生有一科缺考或无卡（且未导入成绩或仅在调整成绩处导入了为0的学科总成绩），则该科目不参与统计；若某个考生所有参考科目均缺考或无卡，则该生总分也不参与统计。
            </div>
            <div>
              4、单科缺考/无卡则总分不参统：某个考生,
              只要有一科缺考或无卡（且未导入成绩或仅在调整成绩处导入了为0的学科总成绩），则总分也不参与统计。
            </div>
            <div>
              5、清空所有设置：删除所有的不参与统计设置，包括快捷设置和单独设置。
            </div>
            <div>
              6、快捷设置，在所有成绩确认完毕后，进行最终设置。如果需要进行成绩调整，要在出统计分析前清空所有设置，并重新进行快捷设置。
            </div>
          </div>
          <!-- <div class="quick-title">批量导入不参与统计的学生名单：</div>
          <div class="upload-box">
            <div class="upload">
              <el-upload
                class="upload-demo"
                :action="uploadAvatarURL"
                :headers="uploadHeader"
                :show-file-list="false"
                :file-list="fileList"
                name="file"
                :on-success="handleOnSuccess"
                :limit="1"
              >
                <el-button size="small" type="primary">选择文件</el-button>
              </el-upload>
            </div>
            <el-button type="text" @click="downLoadFile"
              >批量导入模板下载>></el-button
            >
          </div> -->
        </div>
      </template>
      <template v-if="title === '导入“不参统考生”向导'">
        <uploadGuide @download="downloadTemplate" @achivement="achivement" />
      </template>
      <template
        v-if="
          ['设置不参与统计的学校', '清空不参与学校统计考生'].includes(title)
        "
      >
        <div class="flex" style="margin-bottom: 18px">
          <el-button type="text" @click="schoolAll">全选</el-button>
          <el-button type="text" @click="cancleSchool">取消选择</el-button>
        </div>

        <template v-if="title == '设置不参与统计的学校'">
          <el-checkbox
            v-for="(item, index) in schoolIncludesList"
            :key="index"
            v-model="item.isInclude"
            :true-label="0"
            :false-label="1"
            >{{ item.name }}
          </el-checkbox>
        </template>
        <template v-if="title === '清空不参与学校统计考生'">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in schoolList"
              :key="index"
              :label="item.id"
              >{{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </template>
      </template>
      <template v-if="title === '导入不参与学校统计的考生'">
        <ImportUpload
          tips="请确保上传表格中所填“考号”与“姓名”一致。"
          :file-model="{
            name: '不参与学校统计模板.xls',
            url: `https://static.wtjy.com/stat/template/%E4%B8%8D%E5%8F%82%E4%B8%8E%E5%AD%A6%E6%A0%A1%E7%BB%9F%E8%AE%A1%E6%A8%A1%E6%9D%BF.xls`,
          }"
          :disabled="confirmLoading"
          @change="uploadChange"
        />
      </template>
      <span slot="footer" class="dialog-footer">
        <div v-if="title == '快捷设置'" class="btns">
          <el-button @click="clearSetting">清空所有设置</el-button>
          <el-button type="primary" @click="quickSubmit"
            >保存所有设置</el-button
          >
        </div>
        <template v-if="title === '设置不参与统计的学校'">
          <el-button @click="cancle">取消</el-button>
          <el-button
            type="primary"
            :loading="confirmLoading"
            @click="settingSchool"
            >保存</el-button
          >
        </template>
        <template v-if="title === '导入不参与学校统计的考生'">
          <el-button @click="cancle">取消</el-button>
          <el-button
            type="primary"
            :loading="confirmLoading"
            @click="importFile"
            >导入</el-button
          >
        </template>
        <template v-if="title === '清空不参与学校统计考生'">
          <el-button @click="cancle">取消</el-button>
          <el-button
            type="primary"
            :loading="confirmLoading"
            :disabled="checkList.length === 0"
            @click="clearSchoolStudentSubmit"
            >清空</el-button
          >
        </template>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  setstatmethodsetting,
  getstatmethodsetting,
  studentincludepage,
  setstatstudentinclude,
  quicksetstatstudentinclude,
  clearstatstudentinclude,
  importstatstudentinclude,
} from "@/core/api/academic/statistics";
import {
  setstatexcludeschoolsetting,
  importstatstudentincludeschool,
  clearstatstudentincludeschool,
  setstatstudentincludeschool,
} from "@/core/api/academic/union";
import {
  getstatclassoption,
  getstatsubjectoption,
  getstatschooloption,
} from "@/core/api/academic/common";
import { fileDownloadByUrl } from "@/core/util/util";
import { getStore } from "@/core/util/store";
import { mapState } from "vuex";
import uploadGuide from "./uploadGuide.vue";
import ImportUpload from "@/components/ImportUpload.vue";
export default {
  name: "StatTypeSetting",
  components: {
    uploadGuide,
    ImportUpload,
  },
  data() {
    return {
      radio: 0,
      confirmLoading: false,
      form: {
        baseType: 1,
      },
      checkList: [],
      title: "",
      checkboxDisable: false,
      uploadAvatarURL:
        "/report/stat/importstatstudentinclude" + "?id=" + this.$route.query.id,
      quickForm: {
        id: this.$route.query.id,
        excludeNoSheet: 0,
        excludeSubjectNoSheet: 0,
        excludeSubjectZero: 0,
        excludeZero: 0,
      },
      loading: false,
      fileList: [],
      quickDialogVisible: false,
      statTypeList: ["按参统条件统计", "固定统计人数"],
      tableData: [],
      total: 0,
      search: {
        current: 1,
        size: 10,
        id: this.$route.query.id,
        studentType: 0,
        classNum: "ALL",
      },
      uploadHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
      dialogVisible: false,
      studentTypeOption: [
        {
          label: "所有学生",
          value: 0,
        },
        {
          label: "总成绩或单科成绩不参与统计",
          value: 1,
        },
        {
          label: "总成绩和单科成绩都参与统计",
          value: 2,
        },
      ],
      classNumOptions: [
        {
          label: "全部",
          value: "ALL",
        },
      ],
      saveLoading: false,
      subjectList: [],
      rowItem: {},
      rowLoading: false,
      quickLoading: false,
      schoolIncludes: [],
      areaNames: [],
      schoolList: [],
      schoolIncludesList: [],
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  mounted() {
    this.getData();
    this.getClassNumList();

    if (this.$route.query.examType == 1) {
      this.getSchoolList();
    }
  },
  methods: {
    schoolAll() {
      if (this.title === "清空不参与学校统计考生") {
        this.checkList = this.schoolList.map((item) => item.id);
      } else {
        this.schoolIncludesList.forEach((item) => {
          this.$set(item, "isInclude", 0);
        });
      }
    },
    cancleSchool() {
      if (this.title == "清空不参与学校统计考生") {
        this.checkList = [];
      } else {
        this.schoolIncludesList.forEach((item) => {
          this.$set(item, "isInclude", 1);
        });
      }
    },
    uploadChange({ fileList }) {
      this.fileList = fileList[0];
    },
    async studentChange(e, row) {
      try {
        await setstatstudentincludeschool({
          id: this.$route.query.id,
          studentId: row.studentId,
          isIncludeSchool: e,
        });
        this.getStudentList();
      } catch {
        this.getStudentList();
      }
    },
    async submitMuchImport() {
      // 快捷设置导入

      if (this.fileList.originFileObj) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        try {
          this.confirmLoading = true;
          await importstatstudentinclude({
            id: this.$route.query.id,
            file: data,
          });
          this.confirmLoading = false;
          this.fileList = null;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.getStudentList();
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "warning",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    async importFile() {
      if (this.fileList) {
        let data = new FormData();
        data.append("file", this.fileList.originFileObj);
        try {
          this.confirmLoading = true;
          await importstatstudentincludeschool({
            id: this.$route.query.id,
            file: data,
          });
          this.quickDialogVisible = false;
          this.confirmLoading = false;
          this.$message({
            type: "success",
            message: "提交成功",
            showClose: true,
          });
          this.fileList = null;
          this.getStudentList();
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "waring",
          message: "请选择需要导入的文件",
          showClose: true,
        });
      }
    },
    uploadSchoolStudent() {
      this.title = "导入不参与学校统计的考生";
      this.quickDialogVisible = true;
    },
    async getSchoolList() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
    },
    clearSchoolStudent() {
      this.checkList = this.schoolIncludesList.map((item) => item.id);
      this.title = "清空不参与学校统计考生";
      this.quickDialogVisible = true;
    },
    clearSchoolStudentSubmit() {
      if (this.checkList.length) {
        let schools = this.schoolList.filter((item) =>
          this.checkList.includes(item.id)
        );
        let schoolNames = schools.map((item) => item.name);
        let str = schoolNames.join("，");
        this.$confirm(`确定清空${str}不参与校内统计的考生吗`, "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            try {
              this.confirmLoading = true;
              await clearstatstudentincludeschool({
                id: this.$route.query.id,
                isAllSchool: 0,
                schoolIds: this.checkList.join(","),
              });
              this.$message({
                type: "success",
                message: "已清空!",
              });
              this.getStudentList();
              this.quickDialogVisible = false;
              this.confirmLoading = false;
            } catch {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    areaSetting() {
      this.$router.push({
        path: `/academic/statistics/setting/areaSetting`,
        query: { ...this.$route.query },
      });
    },
    validFixedNumber(rule, value, callback, index) {
      let data = this.form.statSchoolSettings[index].fixedNumber;
      if (!data) {
        callback(new Error("请输入统计分数"));
      } else if (!/^[1-9]\d*$/.test(data) && data > 0) {
        callback(new Error("统计人数必须为正整数"));
      } else {
        callback();
      }
    },
    cancle() {
      this.quickDialogVisible = false;
    },
    async settingSchool() {
      this.confirmLoading = true;
      await setstatexcludeschoolsetting({
        id: this.$route.query.id,
        excludeSchools: this.schoolIncludesList,
      });
      this.confirmLoading = false;
      this.$message({
        type: "success",
        message: "提交成功",
        showClose: true,
      });
      this.getData();
      this.quickDialogVisible = false;
    },
    async schoolSetting() {
      const res = await getstatschooloption({ id: this.$route.query.id });
      this.schoolList = res.data.data;
      this.title = "设置不参与统计的学校";
      this.quickDialogVisible = true;
    },
    searchChange() {
      this.search.current = 1;
      this.getStudentList();
    },
    achivement() {
      this.quickDialogVisible = false;
      this.getStudentList();
    },
    uploadResult() {
      this.quickDialogVisible = true;
      this.title = "导入“不参统考生”向导";
    },
    searchSubmit() {
      this.search.current = 1;
      this.getStudentList();
    },
    handleCurrentChange(val) {
      this.search.current = val;
      this.getStudentList();
    },
    handleSizeChange(val) {
      this.search.size = val;
      this.getStudentList();
    },
    excludeZeroChanges() {
      if (this.quickForm.excludeZero == 1) {
        this.quickForm.excludeNoSheet = 1;
        this.form.excludeZero = 1;
        this.checkboxDisable = true;
      } else {
        this.form.excludeZero = 0;
        this.checkboxDisable = false;
      }
      this.setexCludeZero();
    },
    handleOnSuccess(res) {
      if (res.code == 0) {
        this.$message({
          showClose: true,
          message: "上传成功",
          type: "success",
        });
        this.quickDialogVisible = false;
        this.getStudentList();
      }
    },
    initSetting() {
      this.quickForm = {
        id: this.$route.query.id,
        excludeNoSheet: 0,
        excludeSubjectNoSheet: 0,
        excludeSubjectZero: 0,
        excludeZero: 0,
      };
    },
    quickSubmit() {
      this.$confirm(
        "设置后想要取消掉，则只能手动逐个删除或一键清空，确认设置吗？",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.quickLoading = true;
        quicksetstatstudentinclude({ ...this.quickForm }).then(() => {
          this.quickDialogVisible = false;
          this.quickLoading = false;
          this.getStudentList();
          this.initSetting();
          this.getData();
          this.$message({
            showClose: false,
            message: "提交成功",
            type: "success",
          });
        });
      });
    },
    clearSetting() {
      this.$confirm("点击之后将会清空所有设置？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        clearstatstudentinclude({ id: this.$route.query.id }).then(() => {
          this.getStudentList();
          this.quickDialogVisible = false;
          this.getData();
        });
      });
    },
    allCheck() {
      this.rowItem.subjectIds = this.subjectList.map((item) => item.id);
    },
    reverse() {
      let arr = this.subjectList.map((item) => item.id);
      const data = arr.filter(
        (item) => !this.rowItem.subjectIds.includes(item)
      );
      this.rowItem.subjectIds = [...data];
    },
    cancel() {
      this.rowItem.subjectIds = [];
    },
    saveItem() {
      this.rowLoading = true;
      const subjects = this.rowItem.subjectIds.toString();
      const subjectIds = this.subjectList.map((item) => item.id);
      const includeSubjects = subjectIds.filter(
        (item) => !this.rowItem.subjectIds.includes(item)
      );
      setstatstudentinclude({
        id: this.$route.query.id,
        excludeSubjects: subjects,
        includeSubjects: includeSubjects.toString(),
        studentId: this.rowItem.studentId,
      })
        .then(() => {
          this.dialogVisible = false;
          this.rowLoading = false;
          this.getStudentList();
          this.$message({
            showClose: true,
            message: "提交成功",
            type: "success",
          });
        })
        .catch(() => {
          this.rowLoading = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCloseQuick() {
      this.quickDialogVisible = false;
    },
    async getstatsubjectoptionData() {
      let params = {};

      if (this.$route.query.examType == 1) {
        params = {
          id: this.$route.query.id,
          hasTotal: 1,
          viewSchoolId: this.form.stat.schoolId,
        };
      } else {
        params = {
          id: this.$route.query.id,
          hasTotal: 1,
        };
      }
      const res = await getstatsubjectoption(params);

      this.subjectList = res.data.data;
    },
    downloadTemplate(fileName) {
      const url =
        "https://static.wtjy.com/stat/template/%E5%AF%BC%E5%85%A5%E4%B8%8D%E5%8F%82%E4%B8%8E%E7%BB%9F%E8%AE%A1%E5%AD%A6%E7%94%9F%E6%A8%A1%E6%9D%BF.xls";
      fileDownloadByUrl(url, fileName);
    },
    async getClassNumList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
        isNeedTeach: 0,
      });
      const arr = res.data.data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      });
      this.classNumOptions = [
        {
          label: "全部",
          value: "ALL",
        },
        ...arr,
      ];
      this.getStudentList();
    },
    async getStudentList() {
      this.loading = true;
      const res = await studentincludepage({ ...this.search });
      this.loading = false;
      this.tableData = res.data.data.records;
      this.total = res.data.data.total;
    },
    setting(row) {
      this.rowItem = { ...row };
      const arr = row.subjects.filter((item) => item.isInclude === 0);
      const data = arr.map((item) => item.subjectId);
      this.$set(this.rowItem, "subjectIds", data);
      this.dialogVisible = true;
    },
    async getData() {
      const res = await getstatmethodsetting({ id: this.$route.query.id });
      this.form = res.data.data;
      this.quickForm.excludeZero = this.form.excludeZero;
      this.checkboxDisable = this.form.excludeZero == 1;
      this.quickForm.excludeNoSheet =
        this.form.stat.batchExcludeNoSheet || this.form.excludeZero;
      this.quickForm.excludeSubjectZero =
        this.form.stat.batchExcludeSubjectZero;
      this.quickForm.excludeSubjectNoSheet =
        this.form.stat.batchExcludeSubjectNoSheet;
      this.areaNames = res.data.data.areaNames;
      this.schoolIncludes = res.data.data.schoolIncludes
        ? res.data.data.schoolIncludes.filter((item) => item.isInclude === 0)
        : [];

      this.schoolIncludesList = res.data.data.schoolIncludes;
      this.getstatsubjectoptionData();
    },
    baseType2submit() {
      this.$refs.statSchoolSettingsForm.validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          setstatmethodsetting(this.form)
            .then((res) => {
              this.saveLoading = false;
              this.$message({
                showClose: true,
                message: res.data.data,
                type: "success",
              });
              this.getStudentList();
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    submit() {
      this.saveLoading = true;
      setstatmethodsetting(this.form)
        .then((res) => {
          this.saveLoading = false;
          this.$message({
            showClose: true,
            message: res.data.data,
            type: "success",
          });
          this.getStudentList();
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    excludeZeroChange() {
      this.quickForm.excludeZero = this.form.excludeZero;
      this.setexCludeZero();
    },
    statClassChange() {
      this.loading = true;
      setstatmethodsetting(this.form).then(() => {
        this.getStudentList();
        this.loading = false;
        this.$message({
          showClose: true,
          message: `设置成功！`,
          type: "success",
        });
      });
    },
    setexCludeZero() {
      this.loading = true;
      setstatmethodsetting(this.form).then(() => {
        this.getStudentList();
        this.loading = false;
        this.$message({
          showClose: true,
          message: `设置成功！零分${
            this.form.excludeZero ? "不" : ""
          }参与统计。`,
          type: "success",
        });
      });
    },
    initSystemNum() {
      this.form.statClassSettings.forEach((item) => {
        item.fixedNumber = item.total;
      });
    },
    settingMax() {
      this.form.statSchoolSettings.forEach((item) => {
        this.$set(item, "fixedNumber", item.total);
      });
    },
    radioChange(index) {
      if (this.scoreType === 1 && index === 1) {
        return false;
      } else {
        if (index !== this.form.baseType - 1) {
          if (this.form.baseType === 1) {
            this.$confirm(
              `切换到"固定统计人数"后，"按参统条件统计"的设置将被清除，确认切换吗？`,
              "温馨提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            ).then(() => {
              this.form.baseType = 2;
              this.submit();
            });
          } else if (this.form.baseType === 2) {
            this.$confirm(
              '切换到"按参统条件统计"后，"固定统计人数"的设置都将失效，确认切换吗？',
              "温馨提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            ).then(() => {
              this.form.baseType = 1;
              this.submit();
            });
          } else {
            this.form.baseType = index + 1;
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popper {
  background: #fff5db !important;
}
.statTypeSetting {
  padding: 20px 0;
  width: 100%;
}
.typeSetting {
  width: 100%;
}
.el-form-item {
  margin-bottom: 0;
}
.fixedNumStat {
  .label {
    font-size: 14px;

    color: #0a1119;
    font-weight: 550;
    margin-bottom: 24px;
  }

  .fixedNumStat-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      margin-right: 18px;

      &-label {
        font-weight: normal;
        text-align: right;
      }
    }
  }
}

.active {
  color: red;
}

.flex {
  display: flex;
  align-items: center;
}

.active_btn {
  color: #2474ed;
  font-size: 14px;
  cursor: pointer;
  margin-right: 24px;
}

.btn {
  color: #5b625e;
  font-size: 14px;
  cursor: pointer;
  margin-right: 24px;
}

::v-deep .el-radio {
  margin-bottom: 0;
}

.row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;

  .label {
    font-size: 14px;
    color: #0a1119;
    margin-right: 10px;
  }

  .round-checkBox {
    .el-checkbox {
      margin-bottom: 0;
    }
  }
}
.wrap-row {
  .label {
    font-size: 14px;
    margin-bottom: 18px;
    color: #0a1119;
  }
}
.mb18 {
  margin-bottom: 18px;
}
.box {
  box-sizing: border-box;
  padding: 18px;
  background: #f9fafc;
  border-radius: 4px;
}
.tag {
  box-sizing: border-box;
  padding: 12px 20px;
  background-color: #f3f8ff;
  color: #3e4551;
  margin-bottom: 18px;
  font-size: 12px;
  .tag-title {
    margin-bottom: 8px;
    color: #262829;
    font-weight: 550;
    i {
      color: #2474ed;
      font-size: 14px;
      margin-right: 8px;
    }
  }
  .text {
    margin-bottom: 8px;
    color: #3e4551;
    font-size: 12px;
  }
}

.marginR20 {
  margin-right: 20px;
}

.radioGroup {
  display: flex;
  align-items: center;

  .radio-item {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #5b625e;
    font-size: 14px;
    user-select: none;
    cursor: pointer;

    .point {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      margin-right: 8px;
    }
  }

  .radio-item-active {
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #080a09;
    font-size: 14px;
    cursor: pointer;

    .point {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #2474ed;
      box-sizing: border-box;
      margin-right: 8px;
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #fff;
        content: "";
      }
    }
  }
  .radio-item-disabled {
    -webkit-filter: grayscale(100%); /* webkit */
    -moz-filter: grayscale(100%); /*firefox*/
    -ms-filter: grayscale(100%); /*ie9*/
    -o-filter: grayscale(100%); /*opera*/
    filter: grayscale(100%);
    filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
    filter: gray; /*ie9- */
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.schoolStat {
  .schoolList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .schoolList-item {
      color: #0a1119;
      font-size: 14px;
      margin-right: 18px;
      margin-bottom: 18px;
      box-sizing: border-box;
      padding: 10px;
      border: 1px dashed #d5d6db;
      border-radius: 4px;
      padding-right: 36px;
    }
  }
}
.save {
  text-align: center;
  margin-top: 50px;
}
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .row {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
}
.left {
  flex: 1;
  margin-right: 40px;
  .left-box {
    display: flex;
    background: #f9fafc;
    flex-direction: column;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 18px;
  }
  .upload-box {
    border-radius: 4px;
    border: 1px solid #ebecee;
    margin-top: 18px;
    box-sizing: border-box;
    padding: 18px;
    text-align: center;
  }
}
.btns {
  text-align: center;
  margin: 10px 0;
}
.title {
  color: #0a1119;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
}
.tipinfo {
  flex: 1;
  .tip {
    color: #2474ed;
    font-size: 12px;
  }
  padding: 10px;
  font-size: 12px;
  line-height: 22px;
  color: #343231;
  background-color: #f3f8ff;
}
// .upload-box {
//   display: flex;
//   align-items: center;
//   margin-top: 10px;
//   .upload {
//     margin-right: 24px;
//   }
// }
.table-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.quick-setting {
  display: flex;
  align-items: flex-start;
}
::v-deep .el-checkbox__label {
  white-space: normal;
}
</style>
